const translationsDE = {
  adsTitle: 'Werbung',
  and: 'und',
  'arrowButton.collapseMenu': 'Menu schliessen',
  'arrowButton.expandMenu': 'Menu öffnen',
  'articleSummary.collapseContent': 'Zusammenfassung verbergen',
  'articleSummary.disclaimerFeedback': 'Feedback senden',
  'articleSummary.disclaimerMoreInfo': 'Mehr erfahren',
  'articleSummary.disclaimerPrivacy': 'Datenschutz',
  'articleSummary.disclaimerText':
    'Die künstliche Intelligenz von Blick lernt noch und macht vielleicht Fehler.',
  'articleSummary.expandContent': 'Mehr anzeigen',
  'articleSummary.text':
    'Die Zusammenfassung von Blick+-Artikeln ist unseren Nutzern mit Abo vorbehalten. Melde dich bitte an, falls du ein Abo hast.',
  back: 'Zurück',
  'blickBites.back': 'Zurück',
  'blickBites.endOfFastlaneTitle': 'Du hast das Ende der Blick Bites erreicht',
  'blickBites.onboarding.title': 'Wische nach oben,',
  'blickBites.onboarding.text': 'um mehr Videos zu sehen',
  'blickBites.onboarding.letsGo': 'Los geht’s',
  'blickBites.readMore': 'Mehr dazu',
  'blickBites.share': 'Teilen',
  'blickPlus.linkSharing.availableSharesText':
    'Mit deinem Abo kannst du jeden Monat {{linkAmount}} Artikel verschenken.',
  'blickPlus.linkSharing.copyButton.copied': 'Link kopiert',
  'blickPlus.linkSharing.copyButton.copy': 'Link kopieren',
  'blickPlus.linkSharing.copyButton.generate': 'Link erstellen',
  'blickPlus.linkSharing.errorText':
    'Etwas ist schief gelaufen. Bitte versuche es später nochmals.',
  'blickPlus.linkSharing.hasRunOutOfSharesText':
    'Du hast diesen Monat alle Geschenke aufgebraucht.',
  'blickPlus.linkSharing.linkToAbos': 'Zu den Abos',
  'blickPlus.linkSharing.linkToFaqs': 'Erfahre mehr',
  'blickPlus.linkSharing.loadingLabel': 'Lädt',
  'blickPlus.linkSharing.notSubscribedText':
    'Mit deinem Abo kannst du jeden Monat 10 Artikel verschenken.',
  'blickPlus.linkSharing.title': 'Artikel verschenken ',
  'chatbot.answerFeedback.text': 'War diese Antwort hilfreich?',
  'chatbot.header.title': 'Dein KI-Helfer',
  'chatbot.helloMessage': 'Hallo! Ich bin BliKI, dein KI-Helfer für News',
  'chatbot.helloMessage.chat':
    'Frag mich nach News, Unterhaltung und mehr. Was möchtest du wissen?',
  'chatbot.answerMessage.initialQuestionsTitle': 'Für dich interessante Themen',
  'chatbot.answerMessage.title': 'Hier ist meine Antwort',
  'chatbot.answerMessage.suggestedQuestionsTitle':
    'Weitere Relevante Vorschläge',
  'chatbot.answerMessage.error':
    'Bitte versuche es erneut. Falls das Problem bleibt, sieh dir die neuesten Schlagzeilen auf unserer Startseite an und komm später wieder.',
  'chatbot.answerMessage.errorTitle': 'Da ist was schief gelaufen',
  'chatbot.answerMessage.loading': 'Durchsuche Blick-Inhalte...',
  'chatbot.loginBox.titleLine1': 'Melde dich bitte an,',
  'chatbot.loginBox.titleLine2': 'um weitere Antworten zu erhalten',
  'chatbot.loginBox.button': 'Kostenlos registrieren',
  'chatbot.loginBox.alreadyRegisteredText': 'Du hast bereits ein Konto?',
  'chatbot.loginBox.alreadyRegisteredLink': 'Anmelden',
  'chatbot.loginBox.checListItem1': 'Eigene Fragen stellen',
  'chatbot.loginBox.checListItem2': 'Kostenlose Nutzung',
  'chatbot.loginBox.checListItem3':
    'Schneller Zugang zu relevanten Themen die dich interessieren',
  'chatbot.footer.loginButton': 'Melde dich an und stelle deine Frage',
  'chatbot.footer.inputPlaceholder': 'Stelle deine Frage hier...',
  'chatbot.footer.moreInfo': 'Erfahre mehr',
  'chatbot.footer.privacy': 'Datenschutz',
  'chatbot.footer.feedback': 'Gib dein Feedback',
  'chatbot.footer.reload': 'Neu laden',
  'chatbot.footer.text':
    'Die Blick KI ist noch am lernen und kann Fehler machen. Fragen zum Sport und Wetter können noch nicht beantwortet werden.',
  'chatbot.onboarding.helloMessage':
    'Ich bin die KI vom Blick mit Zugriff auf alle Artikel im Blick-Archiv. Fragen zum Sport und Wetter kann ich zu diesem Zeitpunkt noch nicht beantworten. Ich lerne noch, darum passieren mir auch mal Fehler.',
  'chatbot.onboarding.helloMessage.title': 'Hallo 👋, ich bin ',
  'chatbot.onboarding.tooltip.input':
    'Gib hier deine Frage ein und ich stelle dir entsprechende Inhalte zusammen.',
  'chatbot.onboarding.tooltip.input.logout':
    'Melde dich an und ich stelle dir massgeschneiderte Inhalte zusammen.',
  'chatbot.onboarding.tooltip.rating':
    'Gib uns dein Feedback. Jeder empfindet die Antworten ein klein wenig anders. BliKI muss noch lernen.',
  'chatbot.summary.links.title': 'BliKI Empfiehlt Dir',
  close: 'Schliessen',
  'cmp.placeholder.description':
    'Möchtest du diesen ergänzenden Inhalt (Tweet, Instagram etc.) sehen? Falls du damit einverstanden bist, dass Cookies gesetzt und dadurch Daten an externe Anbieter übermittelt werden, kannst du alle Cookies zulassen und externe Inhalte direkt anzeigen lassen.',
  'cmp.placeholder.primaryButton': 'Externe Inhalte laden',
  'cmp.placeholder.secondaryButton': 'Mehr Infos',
  'cmp.placeholder.title': 'Externe Inhalte',
  'commenting.answer': 'Antworten',
  'commenting.answerPlaceholder': 'Schreibe eine Antwort...',
  'commenting.answerPlaceholderEmailOnly':
    'Vervollständige dein Profil hier, um zu antworten...',
  'commenting.answerPlaceholderNotLoggedIn':
    'Einloggen und eine Antwort schreiben...',
  'commenting.authorType.admin': 'Administrator',
  'commenting.authorType.admin.female': 'Administratorin',
  'commenting.authorType.journalist': 'Journalist',
  'commenting.authorType.journalist.female': 'Journalistin',
  'commenting.authorType.moderator': 'Moderator',
  'commenting.authorType.moderator.female': 'Moderatorin',
  'commenting.authorType.user': 'User',
  'commenting.blickPlusInfoBox.button': 'Zu den Angeboten',
  'commenting.blickPlusInfoBox.loginHint': 'Hast du bereits ein Abo?',
  'commenting.blickPlusInfoBox.loginLinkButton': 'Anmelden',
  'commenting.blickPlusInfoBox.text':
    'Der Kommentarbereich von Blick+-Artikeln ist unseren Nutzern mit Abo vorbehalten. Melde dich bitte an, falls du ein Abo hast. Noch kein Blick+-Abo? Finde unsere Angebote hier:',
  'commenting.blickPlusInfoBox.title': 'Liebe Leserin, Lieber Leser',
  'commenting.cancel': 'Abbrechen',
  'commenting.commentPlaceholder': 'Schreibe einen Kommentar...',
  'commenting.commentPlaceholderEmailOnly':
    'Vervollständige dein Profil hier, um zu kommentieren...',
  'commenting.commentPlaceholderNotLoggedIn':
    'Einloggen und Kommentar schreiben...',
  'commenting.commentingRulesLinkText': 'Kommentarregeln.',
  'commenting.commentingRulesText': 'Hier gehts zu den ',
  'commenting.delete': 'Löschen',
  'commenting.deleteConfirmation':
    'Willst du diesen Kommentar wirklich löschen?',
  'commenting.deletedUserName': 'Dieser Nutzer hat das Konto gelöscht',
  'commenting.loadMoreAnswers': 'Weitere Antworten anzeigen',
  'commenting.loadMoreComments': 'Weitere Kommentare anzeigen',
  'commenting.noAvailableComments': 'Derzeit sind keine Kommentare verfügbar.',
  'commenting.numberOfCharactersLeft':
    'Noch {{numberOfCharacters}} Zeichen übrig',
  'commenting.numberOfCharactersOverflow':
    '{{numberOfCharacters}} Zeichen zuviel',
  'commenting.oneCharacterLeft': 'Noch 1 Zeichen übrig',
  'commenting.oneCharacterOverflow': '1 Zeichen zuviel',
  'commenting.postCommentSentMessage':
    'Besten Dank, dein Kommentar wird überprüft.',
  'commenting.postSubmit': 'Senden',
  'commenting.reaction.critical': 'Skeptisch',
  'commenting.reaction.dislike': 'Gefällt mir nicht',
  'commenting.reaction.happy': 'Witzig!',
  'commenting.reaction.like': 'Gefällt mir',
  'commenting.reaction.sad': 'Traurig',
  'commenting.report': 'Melden',
  'commenting.reportTitle': 'Grund fürs Melden',
  'commenting.reportedMessage': 'Besten Dank für deine Meldung.',
  'commenting.title.top': 'Ausgewählte Stimmen',
  'commenting.to': 'An',
  'config.gdprTitle': 'Datenschutz',
  'cueLive.emptyTickerText': 'Dieser Liveticker hat noch keine Beiträge.',
  'cueLive.endIndicator': 'Ende des Livetickers',
  'cueLive.loadMoreEntries': 'Weitere Einträge laden',
  'cueLive.loadingEntry': 'Lädt Liveticker-Eintrag...',
  'cueLive.pinnedPost': 'Top-Beitrag',
  dossier: 'Dossier',
  'errorPage.errorStatus': 'Fehler {{errorStatus}}',
  'errorPage.helpMessage':
    'Nutze bitte die Navigation oben, um den richtigen Artikel zu finden.',
  'errorPage.pageNotFound': 'Leider existiert diese Seite nicht',
  'errorPage.unknownErrorMessage': 'Ein Fehler ist aufgetreten',
  'feedback.foundMistake': 'Fehler gefunden?',
  'feedback.mailtoBody':
    'Bitte Fehler beschreiben:\n\n\n\n____ \n{{articleUrl}}',
  'feedback.mailtoSubject': 'Fehlermeldung Artikel "{{articleTitle}}"',
  'feedback.reportNow': 'Jetzt melden',
  'floatButton.desktopText': 'Frag BliKI',
  'floatButtonTooltip.text': 'Worüber möchtest du mehr erfahren?',
  'floatButtonTooltip.title': 'Hallo, ich bin BliKI',
  'footer.ringierFooterTitle': 'Mehr von Ringier AG',
  'formIO.loginRequiredButtonText': 'Anmelden',
  'formIO.loginRequiredText': 'Bitte melde dich für eine Teilnahme an!',
  'formIO.noEmailOnlyRequiredButtonText': 'Zum Profil',
  'formIO.noEmailOnlyRequiredText':
    'Vervollständige dein Profil, um mitzumachen.',
  'formIO.submissionMessage': 'Vielen Dank für deine Teilnahme!',
  'gallery.photo': 'Foto',
  googleNewsTitle: 'Folge uns',
  highlights: 'Meine Highlights',
  lastUpdated: 'Aktualisiert',
  less: 'Weniger',
  lessThanMinute: 'vor 1 Minute',
  lessThanNMinutes: 'vor {{diffMinutes}} Minuten',
  'liveEvent.cancelled': 'Abgesagt',
  'liveEvent.dataSkiStageTypes.ABFAHRT': 'Abfahrt',
  'liveEvent.dataSkiStageTypes.GESAMT': 'Gesamt',
  'liveEvent.dataSkiStageTypes.KOMBINATION': 'Kombination',
  'liveEvent.dataSkiStageTypes.RIESENSLALOM': 'Riesenslalom',
  'liveEvent.dataSkiStageTypes.SLALOM': 'Slalom',
  'liveEvent.dataSkiStageTypes.SUPERG': 'Super G',
  'liveEvent.finished': 'Beendet',
  'liveEvent.lap': 'Lap',
  'liveEvent.live': 'LIVE',
  'liveEvent.skiFirstRun': '1. Lauf',
  'liveEvent.skiSecondRun': '2. Lauf',
  'login.logout': 'Abmelden',
  'login.myAccount': 'Mein Konto',
  'lotto.chf': 'CHF',
  'lotto.drawingFrom': 'Ziehung vom {{drawDate}}',
  'lotto.luckyNumber': '+ Glücks-Zahl',
  'lotto.nextDraw': 'Nächste Ziehung: {{nextDate}}',
  'lotto.nextJackpot': 'Nächster Jackpot',
  'lotto.nowPlaying': 'Jetzt spielen',
  'lotto.numberOfWinners': 'Anzahl Gewinner',
  'lotto.prizeCategories': 'Gewinnränge',
  'lotto.profit': 'Gewinn',
  'matchStatus.afterExtraTime': 'Nach Verlängerung',
  'matchStatus.afterPenalty': 'Nach Penaltyschiessen',
  'matchStatus.calledOff': 'Abgesagt',
  'matchStatus.cancelled': 'Abgebrochen',
  'matchStatus.ended': 'Beendet',
  'matchStatus.interrupted': 'Unterbruch',
  'matchStatus.overtime': 'Verlängerung',
  'matchStatus.penaltiesRunning': 'Penaltyschiessen',
  'relatedMatches.today': 'Heute',
  'relatedMatches.tomorrow': 'Morgen',
  more: 'Mehr',
  newest: 'Meine neusten Artikel',
  next: 'Weiter',
  no: 'Nein',
  published: 'Publiziert',
  'published.at': 'um',
  'recipe.cookTime': 'Kochzeit',
  'recipe.ingredients': 'Zutaten',
  'recipe.instructions': 'Zubereitung',
  'recipe.nutrition': 'Nährwert / Person',
  'recipe.portions': 'Portionen',
  'recipe.prepTime': 'Zubereitungs-Zeit',
  'recipe.step': 'Schritt',
  'recommendation.title': 'Das könnte dich auch interessieren',
  'relatedMatches.error.title': 'Da ist was schief gelaufen',
  'relatedMatches.error.text':
    'Es gibt leider Verbindungsprobleme. Bitte versuche es später nochmals.',
  'relatedMatches.emptyData.title': 'Keine Daten vorhanden',
  'relatedMatches.emptyData.text':
    'Anscheinend gibt es in den nächsten Tagen keine Spiele.',
  'scoreboard.versus': 'Vs',
  'search.defaultButtonTitle': 'Suchen',
  'search.description':
    'Melde dich kostenlos an und erhalte uneingeschränkten Zugang zu allen Inhalten von Blick.ch.',
  'search.errorMessage':
    'Die Suche ist momentan nicht verfügbar. Bitte versuche es in einigen Augenblicken noch einmal.',
  'search.input': 'Suche...',
  'search.loading': 'Suche lädt...',
  'search.moreInfoLabel': 'Mehr erfahren',
  'search.noResultsExplanation':
    'Leider haben wir keine passenden Resultate gefunden.',
  'search.of': 'von',
  'search.onlyOneSearchResult': 'Suchergebnis',
  'search.searchResults':
    'Wir haben {{totalElements}} passende Resultate für dich.',
  'search.singleSearchResult': 'Wir haben 1 passendes Resultat für dich.',
  shareMessage: 'Geteilt über Blick',
  'social.copied': 'Link kopiert',
  'social.copy': 'Link kopieren',
  'social.copyToast': 'Link kopiert',
  'social.share': 'Teilen',
  'social.playTextToSpeech': 'Artikel vorlesen',
  'social.stopTextToSpeech': 'Artikel vorlesen beenden',
  'sportTable.goalDiffTotal': 'TD',
  'sportTable.matchesTotal': 'SP',
  'sportTable.name': 'Mannschaft',
  'sportTable.pointsTotal': 'PT',
  'stadiumInfo.capacity': 'Kapazität',
  submit: 'Senden',
  team: 'Mein Team',
  'topList.commentCount': 'Kommentare',
  'topStories.defaultTitle': 'Top-Themen',
  'video.blickPlus.buttonLabel': 'Jetzt Blick+-Optionen ansehen',
  'video.blickPlus.mainText':
    'Dieses Video ist Teil unseres Blick+-Angebots. Mit Blick+ hast du ausserdem Zugang zu vielen weiteren spannenden Inhalten.',
  'video.captionsDisabled': 'Untertitel nicht verfügbar',
  'video.captionsOff': 'Untertitel aus',
  'video.captionsOn': 'Untertitel ein',
  'video.error.browserNonRecoverable.text':
    'Das Video wird von deinem Browser <br/> nicht unterstützt.',
  'video.error.browserNonRecoverable.title': 'Versuche einen anderen Browser',
  'video.error.defaultNonRecoverable.text':
    'Bitte versuche das Video erneut zu laden, oder <br/> schaue später nochmals vorbei.',
  'video.error.defaultNonRecoverable.title': 'Unerwarteter Fehler aufgetreten',
  'video.error.geolocationNonRecoverable.text':
    'Das Video ist in deinem Land <br/>nicht verfügbar.',
  'video.error.geolocationNonRecoverable.title':
    'Video ist leider nicht verfügbar',
  'video.error.loadingNonRecoverable.text':
    'Hol dir gemütlich einen Kaffee oder Tee <br/> und versuche es später nochmals.',
  'video.error.loadingNonRecoverable.title': 'Video kann nicht geladen werden',
  'video.error.loadingRecoverable.text':
    'Das Video konnte nicht geladen werden. <br/> Bitte versuche es erneut.',
  'video.error.loadingRecoverable.title': 'Da ist was schief gelaufen',
  'video.error.networkNonRecoverable.text':
    'Es gibt leider Verbindungsprobleme. <br/> Bitte versuche es später nochmals.',
  'video.error.networkNonRecoverable.title': 'Kein Internet vorhanden',
  'video.error.networkRecoverable.text':
    'Bitte überprüfe deine Verbindung und <br/>versuche das Video erneut zu laden.',
  'video.error.networkRecoverable.title': 'Das Internet hängt fest',
  'video.error.playingNonRecoverable.text':
    'Hol dir gemütlich einen Kaffee oder Tee <br/> und versuche es später nochmals.',
  'video.error.playingNonRecoverable.title':
    'Video kann nicht abgespielt werden',
  'video.fullscreenOff': 'Vollbild beenden',
  'video.fullscreenOn': 'Vollbild',
  'video.liveLabel': 'Live',
  'video.moreVideosOff': 'Videoempfehlungen aus',
  'video.moreVideosOn': 'Videoempfehlungen ein',
  'video.moreVideosUnavailable': 'Videoempfehlungen nicht verfügbar',
  'video.pipOn': 'Bild-in-Bild ein',
  'video.reload': 'NEU LADEN',
  'video.replay': 'Wiederholen',
  'video.resumePlayback': 'Das Video fortsetzen?',
  'video.resumePlayback.continue': 'Fortsetzen',
  'video.resumePlayback.replay': 'Neu starten',
  'video.trailer': 'Trailer abspielen',
  'video.trailer.blickPlus.mainText':
    'Mit Blick+ hast du Zugang zum kompletten Video und vielen weiteren Inhalten.',
  'video.trailer.blickPlus.title': 'Hat dir der Trailer gefallen?',
  'video.unmuteCTAButton': 'Ton an?',
  'video.withVideoLabel': 'Mit Video',
  'sectionSummary.disclaimerText':
    'Die Blick KI ist noch am lernen und kann Fehler machen. Fragen zum Sport und Wetter können noch nicht beantwortet werden.',
  'sectionSummary.disclaimerMoreInfo': 'Mehr erfahren',
  'sectionSummary.disclaimerPrivacy': 'Datenschutz',
  'sectionSummary.disclaimerFeedback': 'Feedback senden',
  'sectionSummary.request.text':
    'BliKI fasst mit Hilfe künstlicher Intelligenz die wichtigsten Neuigkeiten aus dem In- und Ausland, Sport, Wirtschaft sowie Politik automatisch zusammen.',
  'sectionSummary.request.primaryButton': 'Zusammenfassung erstellen',
  'sectionSummary.request.error':
    'Es tut uns leid. Das hat nicht funktioniert. Versuchen Sie es später noch einmal.',
  'sectionSummary.request.error.primaryButton': 'Neu laden',
  'summary.sources.title': 'Quellen',
  'summary.sources.subtitle.singular': 'Inhalte aus 1 Blick-Artikel genutzt',
  'summary.sources.subtitle.plural':
    'Inhalte aus {{totalElements}} Blick-Artikeln genutzt',
  'summary.result.title': 'Deine Antwort',
  'textToSpeechPlayer.error': 'Vorlesefunktion derzeit nicht verfügbar',
  'textToSpeechPlayer.playbackRateUp': 'Wiedergabegeschwindigkeit erhöhen',
  yes: 'Ja',
} as const

export type TranslationsDE = typeof translationsDE

export default translationsDE
