import { useCallback, useEffect, useState } from 'react'
import { extractPageType, hasImmersiveHero } from '@utils/cook'
import { getPageMetadata } from '@hooks/usePageMetadata'
import { validMatchId, validTypeOfSport } from '@hooks/useSportsEvent'
import { LiveEventDataSportTypes } from '@utils/formatters/liveEvent'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import useExecuteOnClientNavigation, {
  UseExecuteOnClientNavigation,
} from './useExecuteOnClientNavigation'

type PageIntentity = {
  isArticle: boolean
  isDossier: boolean
  isRecipe: boolean
  isAuthor: boolean
  isVideoOnly: boolean
  isHerotelling: boolean
  isStorytelling: boolean
  isImmersive: boolean
  isHomePage: boolean
  hasScoreboard: boolean
}

export type UseGetPageIdentity = () => PageIntentity

type GetPageIdentity = (queryClient: QueryClient) => PageIntentity

const getPageIdentity: GetPageIdentity = (queryClient) => {
  const pageMetadata = getPageMetadata(queryClient)
  const isRecipe = pageMetadata.targetContentType === 'recipe'
  const isDossier = extractPageType(pageMetadata) === 'dossier'
  const isArticle = extractPageType(pageMetadata) === 'article'
  const isAuthor = pageMetadata.targetContentType === 'author'
  const isVideoOnly = pageMetadata.targetContentType === 'video'
  const isHerotelling = pageMetadata.targetContentSubType === 'hero'
  const isStorytelling = pageMetadata.targetContentSubType === 'normal'
  const isImmersive = hasImmersiveHero(pageMetadata)
  const isHomePage = pageMetadata.context === 'home'
  const hasScoreboard = [
    pageMetadata.targetContentType === 'livescore',
    validMatchId(pageMetadata.matchId as string),
    validTypeOfSport(pageMetadata.typeOfSport as LiveEventDataSportTypes),
  ].every(Boolean)

  return {
    isArticle,
    isDossier,
    isRecipe,
    isAuthor,
    isVideoOnly,
    isHerotelling,
    isStorytelling,
    isImmersive,
    isHomePage,
    hasScoreboard,
  }
}

const useGetPageIdentity: UseGetPageIdentity = () => {
  const queryClient = useQueryClient()

  const [pageIdentity, setPageIdentity] = useState<PageIntentity>(() =>
    getPageIdentity(queryClient)
  )

  const updatePageIdentity = useCallback<
    Parameters<UseExecuteOnClientNavigation>[0]
  >(() => {
    setPageIdentity(getPageIdentity(queryClient))
  }, [queryClient])

  useEffect(() => {
    setPageIdentity(getPageIdentity(queryClient))
  }, [queryClient])

  useExecuteOnClientNavigation(updatePageIdentity)

  return pageIdentity
}

export default useGetPageIdentity
