import { FunctionComponent, SyntheticEvent, useCallback, useState } from 'react'
import translate from '@i18n'
import config from '@config'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import styled, { css } from 'styled-components'
import SummaryContainer from '@components/Summary/SummaryContainer'
import SummaryContent from '@components/Summary/SummaryContent'
import SummaryDisclaimer from '@components/Summary/SummaryDisclaimer'
import usePageMetadata from '@hooks/usePageMetadata'
import useSubscriptionStatus from '@hooks/useSubscriptionStatus'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'
import useModalOverlayPortal from '@hooks/useModalOverlayPortal'
import UserSnapInlineForm from '@components/UserSnap/InlineForm'
import PrimaryCTAButton from '@components/Buttons/PrimaryCTAButton'
import SummaryHeaderWrapper from '@components/Summary/SummaryHeaderWrapper'

const {
  articleSummary: { moreInfoLink, userSnapFeedbackKey },
} = config

interface Props {
  id: string
  kind: string[]
  title: string
  text: string
}

const CollapsedWrapper = styled.div<{
  isExpanded: boolean
  initialHeight: number
}>`
  ${({
    isExpanded,
    initialHeight,
    theme: {
      color: {
        tertiary: { grey100 },
      },
      spacing: { spacing24 },
    },
  }) => css`
    align-items: center;
    appearance: none;
    border: 0;
    bottom: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    left: 0;
    padding-bottom: 0 0 4px;
    position: absolute;
    right: 0;
    text-align: center;
    width: 100%;
    z-index: 0;
    padding-bottom: ${spacing24};

    &::after {
      background: linear-gradient(0deg, ${grey100} 53%, transparent 100%);
      bottom: 0;
      content: '';
      display: block;
      height: ${initialHeight}px;
      left: 0;
      position: absolute;
      right: 0;
      width: 100%;
      z-index: -1;
    }

    ${isExpanded &&
    css`
      background-color: transparent;

      &::after {
        display: none;
      }
    `}
  `}
`

const ArticleSummary: FunctionComponent<Props> = ({ title, text }) => {
  const { isPlus } = usePageMetadata()
  const { subscriptionStatus } = useSubscriptionStatus()
  const [isExpanded, setIsExpanded] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { portalNode, enterModalOverlay, exitModalOverlay, InPortal } =
    useModalOverlayPortal()
  const initialHeight = 135
  const isNotSubscribedOnBlickPlus =
    isPlus && subscriptionStatus !== 'subscribed'
  const summaryText = isNotSubscribedOnBlickPlus
    ? translate('articleSummary.text')
    : text

  const trackingOnIsCollapsedClick = useCallback<TrackingFnType>(
    () => ({
      event: 'article_summary_click',
    }),
    []
  )
  const widgetImpressionHandler: TrackingFnType = () => ({
    event: 'article_summary_impression',
  })

  const handleIsCollapsedTrack = useTracking(trackingOnIsCollapsedClick)
  const toggleIsExpanded = useCallback(
    (e?: SyntheticEvent) => {
      e?.preventDefault?.()
      if (!isExpanded) {
        handleIsCollapsedTrack()
      }

      setIsExpanded(!isExpanded)
    },
    [handleIsCollapsedTrack, setIsExpanded, isExpanded]
  )

  const trackWidgetImpression = useTracking(widgetImpressionHandler)

  const viewportRef = useViewportTracking({
    track: true,
    onImpression: trackWidgetImpression,
  })

  const trackingOnClick = useCallback<TrackingFnType<{ label: string }>>(
    ({ extraData: { label } }) => ({
      event: 'article_summary_click',
      button_label: label,
    }),
    []
  )
  const handleTrackingOnClick = useTracking(trackingOnClick)

  const disclaimer = {
    title: translate('articleSummary.disclaimerText'),
    links: [
      {
        label: translate('articleSummary.disclaimerMoreInfo'),
        link: moreInfoLink,
        target: '_blank',
        onClickHandler: (label: string) => {
          handleTrackingOnClick({ label })
        },
      },
      {
        label: translate('articleSummary.disclaimerFeedback'),
        onClickHandler: (label: string, event?: PointerEvent) => {
          event?.preventDefault()
          handleTrackingOnClick({ label })
          setIsModalVisible(true)
          enterModalOverlay()
        },
      },
    ],
  }

  return (
    <div ref={viewportRef}>
      <SummaryContainer isExpanded={isExpanded} height={initialHeight}>
        <SummaryHeaderWrapper
          title={title}
          enabled={isExpanded}
          onCloseButtonClick={toggleIsExpanded}
          showBorder={false}
        />

        {summaryText && <SummaryContent text={summaryText} />}
        {(!isNotSubscribedOnBlickPlus || !isPlus) && (
          <>
            <SummaryDisclaimer {...disclaimer} />
            {portalNode && isModalVisible && (
              <InPortal node={portalNode}>
                <UserSnapInlineForm
                  setIsVisible={(isVisible) => {
                    if (!isVisible) {
                      exitModalOverlay()
                    }
                    enterModalOverlay()
                  }}
                  projectApiKey={userSnapFeedbackKey}
                />
              </InPortal>
            )}
          </>
        )}
        {!isExpanded && (
          <CollapsedWrapper
            aria-label={translate(
              `articleSummary.${isExpanded ? 'collapse' : 'expand'}Content`
            )}
            isExpanded={isExpanded}
            initialHeight={initialHeight}>
            <PrimaryCTAButton
              type="button"
              onClick={toggleIsExpanded}
              iconName="chevron-down"
              size="small">
              {translate('articleSummary.expandContent')}
            </PrimaryCTAButton>
          </CollapsedWrapper>
        )}
      </SummaryContainer>
    </div>
  )
}

const widget = {
  kind: ['article-summary'],
  component: ArticleSummary,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
