import { ConfigFn } from '@config/types'

const config = (({ deploymentEnv, searchUrl }) =>
  ({
    urlHashParam: 'bid',
    utmContent: 'blick-bites',
    blickBitesApiUrl: `${searchUrl}/bites`,
    localStorageHideOnboardingOverlayKey: 'blickBitesHideOnboardingOverlay',
    aureusQueryName: 'polaris-bites',
    aureusQuerySection: `polaris-bites-${deploymentEnv === 'prod' ? 'prod' : 'stg'}`,
    aureusResultsLimit: 5,
  }) as const) satisfies ConfigFn

export default config
