import {
  desktopLeftAlignedCSS,
  desktopCSS,
  mobileCSS,
  tabletCSS,
  layout,
  mobileAndTabletCSS,
} from '@measures/responsive'
import { FunctionComponent, ReactNode, useEffect, useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'
import { StoryWrapType } from '@stories/utils/types'
import useGetPageIdentity from '@hooks/useGetPageIdentity'
import WelcomeAd from '@components/WelcomeAd'
import Header, { StyledHeaderWrapper } from '@components/Header'
import Footer, { FooterWrapper } from '@components/Footer'
import { enhanceComponentWithErrorBoundary } from '@utils/sentry'
import useBrandingDay, { BrandingDayOptionsType } from '@hooks/useBrandingDay'
import useIsContentForceCentered from '@hooks/useIsContentForceCentered'
import { useQueryClient } from '@tanstack/react-query'
import usePageMetadata from '@hooks/usePageMetadata'
import JSONRenderer from '@components/JSONRenderer'
import { StyledArticleMainContent } from '@components/ArticleMainContent'
import { StyledArticleSidebarContent } from '@widgets/ArticleSidebarContent'
import { printCSS } from '@utils/style'
import { StyledWrapper as AdInnerWrapper } from '@components/Ad/Wrapper'
import dynamic from 'next/dynamic'
import useIsCUIEnabled from '@hooks/useIsCUIEnabled'
import useIsContentRestricted from '@hooks/useIsContentRestricted'
import useABTestValue from '@hooks/useABTestValue'

export interface WrapperOfAllProps {
  children: ReactNode
  storybookWrapType?: StoryWrapType
}

type StyledBrandingDay = Pick<
  BrandingDayOptionsType,
  'bgColor' | 'bgFixed' | 'bgImgUrl' | 'bgCover' | 'paddingTop'
> & { isVisible: boolean }

interface StyledAnchorProps {
  isBrandingDayVisible: boolean
}

interface MainContentWraperProps {
  isArticle: boolean
  hasContentSidebarOnDesktop: boolean
  isImmersive: boolean
}

interface StyledWrapperOfAllProps {
  isHerotelling: boolean
  isArticle: boolean
  isContentForceCentered: boolean
  isContentRestricted: boolean
}

const ThemeSwitcher = dynamic(() => import('@components/ThemeSwitcher'), {
  ssr: false,
})

const ChatbotEntry = dynamic(() => import('@components/Chatbot/ChatbotEntry'), {
  ssr: false,
})

const Chatbot = dynamic(() => import('@components/Chatbot/Chatbot'), {
  ssr: false,
})

const [HeaderWithErrorBoundary, FooterWithErrorBoundary] = [Header, Footer].map(
  (Component) => enhanceComponentWithErrorBoundary(Component)
)

const {
  header: {
    desktop: {
      offsetFirstRow: headerOffsetFirstRowDesktop,
      totalOffset: headerTotalOffsetDesktop,
    },
    mobileAndTablet: { totalOffset: headerTotalOffsetMobileAndTablet },
  },
} = layout

const MainContentWrapper = styled.div<MainContentWraperProps>`
  ${({
    theme: {
      spacing: { spacing20, spacing32 },
      colors: { fillDefault },
    },
    isArticle,
    hasContentSidebarOnDesktop,
    isImmersive,
  }) => css`
    position: relative;
    grid-area: mcw;
    min-height: 100vh;
    display: grid;
    align-items: flex-start;
    align-content: flex-start;
    box-sizing: border-box;
    width: 100%;
    background-color: ${fillDefault};
    z-index: 1;

    ${desktopCSS(css`
      padding: 0 15px;
    `)};

    ${tabletCSS(css`
      padding: 0 68px;
    `)};

    ${mobileCSS(css`
      padding: 0 16px;
    `)};

    ${!isArticle &&
    css`
      ${isImmersive &&
      css`
        ${mobileAndTabletCSS(css`
          margin-top: -${headerTotalOffsetMobileAndTablet}px;
        `)};
      `};
      grid-template-columns: minmax(0, 1fr);
      grid-row-gap: ${spacing20};
    `};

    ${isArticle &&
    css`
      grid-row-gap: ${spacing20};

      ${desktopCSS(css`
        grid-template-columns: repeat(6, minmax(0, 1fr));
        grid-column-gap: ${spacing32};

        > *:not(${StyledArticleMainContent}):not(
            ${StyledArticleSidebarContent}
          ) {
          grid-column: 1 / 7;
          width: calc(100% + 2 * 15px);
          margin-left: -15px;
          margin-right: 0;
        }

        ${hasContentSidebarOnDesktop
          ? css`
              > ${StyledArticleMainContent} {
                grid-column: 1 / 5;
              }

              > ${StyledArticleSidebarContent} {
                grid-column: 5 / 7;
              }
            `
          : css`
              > ${StyledArticleMainContent} {
                grid-column: 2 / 6;
              }

              > ${StyledArticleSidebarContent} {
                display: none;
              }
            `};
      `)};

      ${tabletCSS(css`
        grid-template-columns: minmax(0, 1fr);

        > ${StyledArticleSidebarContent} {
          display: none;
        }

        > *:not(${StyledArticleMainContent}) {
          width: calc(100% + 2 * 68px);
          margin-left: -68px;
          margin-right: 0;
        }
      `)};

      ${mobileCSS(css`
        grid-template-columns: minmax(0, 1fr);

        > ${StyledArticleSidebarContent} {
          display: none;
        }

        > *:not(${StyledArticleMainContent}):not(
            ${StyledArticleSidebarContent}
          ) {
          width: calc(100% + 2 * 16px);
          margin-left: -16px;
          margin-right: 0;
        }
      `)};
    `};
  `}
`

const StyledBrandingDay = styled.div<StyledBrandingDay>`
  ${({ bgColor, bgFixed, bgImgUrl, bgCover, paddingTop, isVisible }) =>
    !isVisible
      ? css`
          display: none;
        `
      : css`
          display: block;
          position: sticky;
          top: 62px;
          left: 0;
          width: 100vw;
          height: calc(100vh - 62px);
          background-repeat: no-repeat;
          ${bgColor && `background-color: ${bgColor};`}
          background-attachment: ${bgFixed ? 'fixed' : 'scroll'};
          ${bgImgUrl && `background-image: url('${bgImgUrl}');`}
          background-repeat: 'no-repeat';
          ${bgCover && 'background-size: cover;'}
          background-position: ${paddingTop
            ? `center ${paddingTop}px`
            : 'center'};
        `}
`

const BrandingDayWrapper = styled.div`
  ${({
    theme: {
      colors: { fillWeak },
    },
  }) => css`
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${fillWeak};

    ${printCSS(css`
      display: none;
    `)};
  `}
`

const LeftSidebar = styled.div`
  position: relative;
  position: sticky;
  box-sizing: border-box;
  z-index: 1;
`

const RightSidebar = styled.div`
  position: relative;
  position: sticky;
  box-sizing: border-box;
  z-index: 1;
`

const AdWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  > ${AdInnerWrapper} {
    position: relative;
  }
`

const StyledAnchor = styled.a<StyledAnchorProps>`
  ${({ isBrandingDayVisible }) =>
    !isBrandingDayVisible
      ? css`
          display: none;
        `
      : css`
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;
        `}
`

const StyledWrapperOfAll = styled.div<StyledWrapperOfAllProps>`
  ${({
    isContentForceCentered,
    isArticle,
    isHerotelling,
    isContentRestricted,
    theme: {
      colors: { fillDefault },
    },
  }) => css`
    position: relative;
    display: grid;
    align-items: flex-start;
    align-content: flex-start;
    background-color: ${fillDefault};

    ${isContentRestricted &&
    css`
      position: fixed;
      width: 100dvw;
    `}

    > ${StyledHeaderWrapper} {
      grid-area: hdr;
    }

    > ${FooterWrapper} {
      grid-area: ftr;
      z-index: 1;
    }

    > ${LeftSidebar}, > ${RightSidebar} {
      height: calc(
        100vh -
          ${isArticle
            ? headerOffsetFirstRowDesktop
            : headerTotalOffsetDesktop}px
      );
      top: ${isArticle
        ? headerOffsetFirstRowDesktop
        : headerTotalOffsetDesktop}px;

      ${mobileAndTabletCSS(css`
        display: none;
      `)};
    }

    > ${LeftSidebar} {
      grid-area: lsb;

      ${desktopLeftAlignedCSS(css`
        ${!isContentForceCentered &&
        css`
          display: none;
        `};
      `)};
    }

    > ${RightSidebar} {
      grid-area: rsb;
    }

    ${desktopCSS(css`
      grid-template-columns: minmax(0, 1fr) 994px minmax(0, 1fr);
      grid-template-rows: repeat(3, auto);

      ${isHerotelling &&
      css`
        grid-template-areas:
          'hdr hdr hdr'
          '... mcw ...'
          '... ftr ...';
      `};

      ${!isHerotelling &&
      css`
        grid-template-areas:
          'hdr hdr hdr'
          'lsb mcw rsb'
          'lsb ftr rsb';

        ${desktopLeftAlignedCSS(css`
          ${!isContentForceCentered &&
          css`
            grid-template-columns: 994px minmax(0, 1fr);
            grid-template-areas:
              'hdr hdr'
              'mcw rsb'
              'ftr rsb';
          `};
        `)};
      `};
    `)};

    ${tabletCSS(css`
      grid-template-columns: minmax(0, 1fr) 768px minmax(0, 1fr);
      grid-template-areas:
        'hdr hdr hdr'
        '... mcw ...'
        '... ftr ...';
    `)};

    ${mobileCSS(css`
      grid-template-columns: minmax(0, 1fr);
      grid-template-areas:
        'hdr'
        'mcw'
        'ftr';
    `)};
  `}
`

const StyledStickyWrapper = styled.div`
  ${() => css`
    position: sticky;
    top: 0;
    height: 0;
    width: 100%;
    z-index: 5;
  `}
`

const WrapperOfAll: FunctionComponent<WrapperOfAllProps> = ({
  children,
  storybookWrapType,
}) => {
  const queryClient = useQueryClient()
  const isChatbotEnabled = useIsCUIEnabled()
  const {
    isArticle,
    isStorytelling,
    isHerotelling,
    isImmersive,
    isVideoOnly,
    isRecipe,
    isDossier,
    isAuthor,
  } = useGetPageIdentity()
  const { isVisible: isBrandingDayVisible, options: brandingDayOptions } =
    useBrandingDay()
  const isContentForceCentered = useIsContentForceCentered()
  const sidebarAdRef = useRef<HTMLDivElement>(null)
  const { welcomeAd, sideBarAd, url, hasSideBar } = usePageMetadata()
  const isContentRestricted = useIsContentRestricted()

  const isInStorybook = !!storybookWrapType
  const storybookWrapTypeValue = storybookWrapType?.[2]

  //! The order of the memoized components below is significant.
  //! Specifically, WelcomeAd, BrandingDay, LeftSidebar & RightSidebar needs to be rendered before the page content.
  //! The reason for this is that these ads need to always register first

  const brandingDayAnchorElement = useMemo(
    () =>
      !brandingDayOptions?.clickUrl ? null : (
        <StyledAnchor
          target="_blank"
          rel="noopener nofollow"
          href={brandingDayOptions?.clickUrl}
          isBrandingDayVisible={isBrandingDayVisible}
        />
      ),
    [brandingDayOptions?.clickUrl, isBrandingDayVisible]
  )

  useEffect(() => {
    queryClient.setQueryData(['sidebar-ad-ref'], sidebarAdRef)
    return () => {
      queryClient.removeQueries({ queryKey: ['sidebar-ad-ref'], exact: true })
    }
  }, [queryClient, sidebarAdRef])

  const welcomeAdComponent = useMemo(
    () =>
      !isHerotelling ? (
        <WelcomeAd key={`${url}-welcome-ad`}>{welcomeAd}</WelcomeAd>
      ) : null,
    [isHerotelling, url, welcomeAd]
  )

  const brandingDayComponent = useMemo(
    () =>
      !isHerotelling && isBrandingDayVisible ? (
        <BrandingDayWrapper key={`${url}-branding-day-wrapper`}>
          {!!brandingDayOptions && (
            <StyledBrandingDay
              bgColor={brandingDayOptions.bgColor}
              bgFixed={brandingDayOptions.bgFixed}
              bgImgUrl={brandingDayOptions.bgImgUrl}
              bgCover={brandingDayOptions.bgCover}
              paddingTop={brandingDayOptions.paddingTop}
              isVisible={isBrandingDayVisible}
            />
          )}
        </BrandingDayWrapper>
      ) : null,
    [brandingDayOptions, isBrandingDayVisible, isHerotelling, url]
  )

  const leftSidebarComponent = useMemo(
    () =>
      !isHerotelling ? (
        <LeftSidebar key={`${url}-left-sidebar`}>
          {brandingDayAnchorElement}
        </LeftSidebar>
      ) : null,
    [brandingDayAnchorElement, isHerotelling, url]
  )

  const rightSidebarComponent = useMemo(
    () =>
      !isHerotelling ? (
        <RightSidebar key={`${url}-right-sidebar`}>
          <AdWrapper ref={sidebarAdRef}>
            {brandingDayAnchorElement}
            <JSONRenderer>{sideBarAd}</JSONRenderer>
          </AdWrapper>
        </RightSidebar>
      ) : null,
    [brandingDayAnchorElement, isHerotelling, sideBarAd, url]
  )

  const storybookChildren = useMemo(() => {
    if (isInStorybook) {
      switch (storybookWrapTypeValue) {
        case 'header':
        case 'footer':
          return null
        case 'article-main-content':
          return (
            <>
              <JSONRenderer>
                {{
                  kind: ['article-main-content'],
                  content: children,
                }}
              </JSONRenderer>
              {hasSideBar && (
                <JSONRenderer>
                  {{
                    kind: ['article-sidebar-content'],
                    content: [],
                  }}
                </JSONRenderer>
              )}
            </>
          )
        case 'article-sidebar-content':
          return (
            <>
              <JSONRenderer>
                {{
                  kind: ['article-main-content'],
                  content: [],
                }}
              </JSONRenderer>
              <JSONRenderer>
                {{
                  kind: ['article-sidebar-content'],
                  content: children,
                }}
              </JSONRenderer>
            </>
          )
        default:
          return children
      }
    }
  }, [isInStorybook, storybookWrapTypeValue, children, hasSideBar])

  const articleTypeClassNames = [isStorytelling, isHerotelling, isRecipe].some(
    Boolean
  )
    ? 'single-column-article'
    : [!isArticle, isAuthor, isDossier].some(Boolean)
      ? 'section'
      : 'two-column-article'

  return (
    <>
      {welcomeAdComponent}
      <StyledStickyWrapper>
        {!isInStorybook && isChatbotEnabled && <ChatbotEntry />}
      </StyledStickyWrapper>
      <StyledWrapperOfAll
        className={articleTypeClassNames}
        isHerotelling={isHerotelling}
        isContentForceCentered={isContentForceCentered}
        isArticle={isArticle}
        isContentRestricted={isContentRestricted}>
        {isInStorybook ? (
          storybookWrapTypeValue === 'header' && children
        ) : (
          <HeaderWithErrorBoundary />
        )}
        {brandingDayComponent}
        {leftSidebarComponent}
        {rightSidebarComponent}
        <MainContentWrapper
          isArticle={isArticle}
          hasContentSidebarOnDesktop={!!hasSideBar || isVideoOnly}
          isImmersive={isImmersive}>
          {isInStorybook ? storybookChildren : children}
        </MainContentWrapper>
        {isInStorybook ? (
          storybookWrapTypeValue === 'footer' && children
        ) : (
          <FooterWithErrorBoundary />
        )}
      </StyledWrapperOfAll>
      {!isInStorybook && isChatbotEnabled && <Chatbot />}
      {[!isInStorybook, useABTestValue('showThemeSwitcher') === 'show'].every(
        Boolean
      ) && <ThemeSwitcher />}
    </>
  )
}

export default WrapperOfAll
