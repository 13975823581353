import { ConfigFn } from '@config/types'

const config = (() => {
  return {
    moreInfoLink: 'https://www.blick.ch/id19711657',
    userSnapFeedbackKey: '7af18277-7fb2-46ba-be82-3f1a20e3b2f7',
  } as const
}) satisfies ConfigFn

export default config
