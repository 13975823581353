import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled, { css } from 'styled-components'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'

import config from '@config'
import { desktopCSS } from '@measures/responsive'
import useSwiperArrows from '@hooks/useSwiper/useSwiperArrows'
import useSwiper, { ArrowsVisibilityType } from '@hooks/useSwiper'
import InfiniteScroll from '@components/InfiniteScroll'
import {
  BlickBitesAd,
  BlickBitesData,
  BlickBitesItem,
} from '@widgets/Video/BlickBites'

import BlickBitesFastlaneContextProvider from './BlickBitesFastlaneContextProvider'
import NavigationArrows from './VerticalNavigationArrows'
import BlickBitesFastlaneItem from './BlickBitesFastlaneItem'
import CloseButton from './BlickBitesFastlaneItem/CloseButton'
import Placeholder from './BlickBitesFastlaneItem/Placeholder'
import {
  fetchBlickBites,
  fetchBlickBitesFromSearchAPI,
  isBlickBitesAd,
  pathWithBiteId,
  pathWithoutBiteId,
  transformBites,
} from '../utils'
import EndOfFastlaneItem from './BlickBitesFastlaneItem/EndOfFastlaneItem'
import MobileOnboardingOverlay from './MobileOnboardingOverlay'
import usePageMetadata from '@hooks/usePageMetadata'
import BlickBitesAdItem from '@components/Video/BlickBites/BlickBitesFastlane/BlickBitesAd'
import useHasAureusJsClientScriptInitialized from '@hooks/useHasAureusJsClientScriptInitialized'

const {
  publication: { publication },
} = config

export interface BlickBitesFastlaneProps {
  onClose: () => void
  data: BlickBitesItem[]
  activeIndex: number
  biteId?: string
  adData: BlickBitesAd
}

const StyledBlickBitesFastlane = styled.div`
  ${({
    theme: {
      color: {
        tertiary: { grey1000 },
      },
    },
  }) => css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 7000000;
    background-color: ${grey1000};

    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
  `}
`

const StyledBlickBitesFastlaneInner = styled.div`
  width: 100%;
  height: 100%;

  ${desktopCSS(css`
    width: auto;

    display: grid;
    grid-auto-flow: column;
  `)}
`

const StyledBlickBitesFastlaneCarousel = styled.div`
  width: 100%;
  height: 100%;

  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  > * {
    scroll-snap-align: end;
    scroll-snap-stop: always;

    ${desktopCSS(css`
      scroll-snap-align: start;

      &:nth-last-child(2) {
        margin-bottom: 64px;
      }
    `)}
  }
`

const FastlaneControls = styled.div`
  ${({
    theme: {
      spacing: { spacing32, spacing64 },
    },
  }) => css`
    display: none;

    ${desktopCSS(css`
      min-height: 560px;

      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: ${spacing32} 0 ${spacing64} 25px;
    `)}
  `}
`

const BlickBitesFastlane: FunctionComponent<BlickBitesFastlaneProps> = ({
  data: initialWidgetData,
  activeIndex: initialActiveIndex,
  biteId,
  onClose,
  adData,
}) => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const { url } = usePageMetadata()
  const hasAureusJsClientScriptInitialized =
    useHasAureusJsClientScriptInitialized()

  const itemsContainerRef = useRef<HTMLDivElement | null>(null)

  const [arrowsVisibility, setArrowsVisibility] =
    useState<ArrowsVisibilityType>('none')
  const [activeIndex, setActiveIndex] = useState(initialActiveIndex)

  const onImpression = useCallback((index: number) => {
    setActiveIndex(index)
  }, [])

  const closeHandler = useCallback(() => {
    const routeAsPath = router.asPath
    const newRouteAsPath = pathWithoutBiteId(routeAsPath)
    if (routeAsPath !== newRouteAsPath) {
      router.replace(newRouteAsPath, undefined, {
        shallow: true,
        scroll: false,
      })
    }

    onClose()
  }, [onClose, router])

  const initialData = useMemo<BlickBitesData>(
    () =>
      biteId
        ? {
            content: [],
          }
        : {
            content: initialWidgetData,
            previousCursor: -1,
          },
    [biteId, initialWidgetData]
  )

  const getBitesDeboostedIds = useCallback(
    () => queryClient.getQueryData<string[]>(['bites-deboosted-ids']),
    [queryClient]
  )

  const getBitesLoadedIds = useCallback(() => {
    const currentLoadedData = queryClient.getQueryData<{
      pages: BlickBitesData[]
    }>(['blick-bites', 'items', url])

    return (
      currentLoadedData?.pages.reduce((acc, group) => {
        if (group?.content) {
          acc.push(...group.content.map((item) => item.articleId))
        }
        return acc
      }, [] as string[]) ?? []
    )
  }, [queryClient, url])

  const fetchData = useCallback(
    (pageParam?: number) =>
      publication === 'romandie'
        ? fetchBlickBitesFromSearchAPI({
            before:
              pageParam === initialData.previousCursor ? undefined : pageParam,
            ...(biteId
              ? { id: biteId }
              : {
                  bl: initialWidgetData
                    ?.map((item) => item.articleId)
                    .join(','),
                }),
          })
        : fetchBlickBites({
            before:
              pageParam === initialData.previousCursor ? undefined : pageParam,
            deboostedIds: getBitesDeboostedIds(),
            blackListedIds: getBitesLoadedIds(),
            ...(biteId && { id: biteId }),
          }),
    [
      initialData.previousCursor,
      biteId,
      initialWidgetData,
      getBitesDeboostedIds,
      getBitesLoadedIds,
    ]
  )

  const {
    data: blickBitesData,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['blick-bites', 'items', url],
    queryFn: ({ pageParam }) => fetchData(pageParam),
    getNextPageParam: (lastPage) =>
      lastPage?.content?.length ? lastPage.previousCursor : undefined,
    initialPageParam: initialData.previousCursor,
    initialData: () => ({
      data: initialData,
      pages: [initialData],
      pageParams: [initialData.previousCursor],
    }),
    enabled: false,
    gcTime: 0,
  })

  useEffect(() => {
    if (
      biteId &&
      hasAureusJsClientScriptInitialized &&
      !queryClient.getQueryData<boolean>(['fast-lane-bite-bookmark-fetched'])
    ) {
      queryClient.setQueryData(['fast-lane-bite-bookmark-fetched'], true)
      refetch()
    }
  }, [biteId, refetch, queryClient, hasAureusJsClientScriptInitialized])

  const blickBitesItems = useMemo(
    () => transformBites(blickBitesData, adData),
    [blickBitesData, adData]
  )

  const { swiperRef } = useSwiper({
    initialSlide: initialActiveIndex,
    vertical: true,
    itemsContainerRef,
  })

  const onArrowsVisibility = useCallback((arrow: ArrowsVisibilityType) => {
    setArrowsVisibility(arrow)
  }, [])

  useSwiperArrows({
    enabled: true,
    vertical: true,
    itemsContainerRef,
    itemsCount: blickBitesItems.length,
    onArrowsVisibility: onArrowsVisibility,
  })

  useEffect(() => {
    const currentBiteId = (blickBitesItems[activeIndex] as BlickBitesItem)
      ?.articleId

    if (currentBiteId) {
      const routeAsPath = router.asPath
      const newRouteAsPath = pathWithBiteId(routeAsPath, currentBiteId)

      if (routeAsPath !== newRouteAsPath) {
        router.replace(newRouteAsPath, undefined, {
          shallow: true,
          scroll: false,
        })
      }
    }
  }, [blickBitesItems, activeIndex, router])

  return (
    <BlickBitesFastlaneContextProvider>
      <StyledBlickBitesFastlane>
        <StyledBlickBitesFastlaneInner>
          <StyledBlickBitesFastlaneCarousel ref={itemsContainerRef}>
            {blickBitesItems.length ? (
              <InfiniteScroll
                fetchData={fetchNextPage}
                hasMore={hasNextPage}
                isLoading={isFetchingNextPage}
                threshold={0}
                root={itemsContainerRef.current}
                rootMargin="200% 0px 200% 0px">
                {blickBitesItems?.map((item, index) =>
                  isBlickBitesAd(item) ? (
                    <BlickBitesAdItem
                      adItem={item}
                      isActive={index === activeIndex}
                      onImpression={onImpression}
                      index={index}
                      key={index}
                      onClose={closeHandler}
                    />
                  ) : (
                    <BlickBitesFastlaneItem
                      item={item}
                      key={index}
                      onClose={closeHandler}
                      index={index}
                      activeIndex={activeIndex}
                      onImpression={onImpression}
                    />
                  )
                )}
                {!hasNextPage && <EndOfFastlaneItem onClose={closeHandler} />}
              </InfiniteScroll>
            ) : (
              <Placeholder isLoading={isFetching} onClose={closeHandler} />
            )}
          </StyledBlickBitesFastlaneCarousel>
          <FastlaneControls>
            <CloseButton onClick={closeHandler} />
            <NavigationArrows
              arrowsVisibility={arrowsVisibility}
              swiperRef={swiperRef}
            />
          </FastlaneControls>
        </StyledBlickBitesFastlaneInner>
        <MobileOnboardingOverlay />
      </StyledBlickBitesFastlane>
    </BlickBitesFastlaneContextProvider>
  )
}

export default BlickBitesFastlane
